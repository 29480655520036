import { YouTubePlanData } from "./YouTubePlanning";
import ToplineNumber from "./ToplineNumber";
import { formatNumber } from "../utils/format-utils";
import { BPMTable, Button, ButtonType, Header } from "../Components";
import { MdClose, MdOutlineFileDownload } from "react-icons/md";
import { useCallback } from "react";
import "./Plan.scss";
import SpendVsChart from "./SpendVsChart";

const PLAN_TABLE_HEADERS: Header[] = [
  {
    label: "Format",
    name: "format",
  },
  {
    label: "Targeting Type",
    name: "targetingType",
  },
  {
    label: "Segments",
    name: "segments",
  },
  {
    label: "Budget",
    name: "budget",
  },
  {
    label: "Budget Share",
    name: "budgetShare",
  },
  {
    label: "Reach",
    name: "reach",
  },
  {
    label: "Avg. Freq.",
    name: "avgFreq",
  },
  {
    label: "Cost Per Reach",
    name: "costPerReach",
  },
  {
    label: "Imps",
    name: "imps",
  },
  {
    label: "CPM",
    name: "cpm",
  },
  {
    label: "Views",
    name: "views",
  },
  {
    label: "CPV",
    name: "cpv",
  },
];

interface PlanProps {
  planData?: YouTubePlanData;
  onClose: () => any;
}

export const Plan: React.FC<PlanProps> = ({ planData, onClose }) => {
  const downloadPlan = useCallback(() => {
    // TODO: functionality to export a plan
  }, []);

  return (
    <div className="youTubePlan">
      <div className="youTubePlanHeader">
        <div className="youTubePlanHeaderTextContainer">
          <div className="youTubePlanHeaderText">Recommendations and Estimates</div>
          <div className="youTubePlanHeaderSubtext">
            Maximize Views, $1.2M Budget, All Converters, Northwest
          </div>
        </div>
        <div className="youTubePlanHeaderControls">
          <Button
            className="youTubePlanDownloadButton"
            type={ButtonType.FILLED}
            onClick={downloadPlan}
          >
            <MdOutlineFileDownload />
          </Button>
          <Button className="youTubePlanCloseButton" type={ButtonType.EMPTY} onClick={onClose}>
            <MdClose />
          </Button>
        </div>
      </div>
      <div className="youTubePlanToplineNumbersContainer">
        <ToplineNumber
          label="Budget"
          value={`$${formatNumber(planData?.suggestedBudget || null)}`}
        />
        <ToplineNumber
          label="On Target Reach"
          value={formatNumber(planData?.onTargetReach || null)}
        />
        <ToplineNumber label="Avg. Frequency" value={formatNumber(planData?.averageFreq || null)} />
        <ToplineNumber label="CPM" value={formatNumber(planData?.cpm || null)} />
        <ToplineNumber
          label="YouTube Population"
          value={formatNumber(planData?.youTubePopulation || null)}
        />
      </div>
      <div className="youTubePlanSpendChartsContainer">
        <SpendVsChart variableName="Reach" data={planData?.spendVsReach || []} />
        <SpendVsChart variableName="Views" data={planData?.spendVsViews || []} />
      </div>
      <div className="youTubePlanTable">
        <BPMTable
          className="draftsTable"
          alternateColors={false}
          data={[
            {
              id: 1,
              format: "Video Reach Campaign",
              targetingType: "In-Market Audiences",
              segments: "",
              budget: "1000000",
              budgetShare: "",
              reach: "",
              avgFreq: "0",
              costPerReach: "0",
              imps: "0",
              views: "0",
              cpv: "0",
            },
          ]}
          headers={PLAN_TABLE_HEADERS}
          filterBar={false}
          noRowsRenderer={() => <div>No rows to show.</div>}
        ></BPMTable>
      </div>
    </div>
  );
};

export default Plan;
