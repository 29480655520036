import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import * as R from "ramda";
import {
  MetaBuyingTableRow,
  CampaignRow,
  AdSetRow,
  AdRow,
  MBApprovalStage,
  DimensionType,
} from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { BPMTable, CheckBox, FilterBar, Header, FilterBarTokens } from "../Components";
import ReviewModal, {
  ReviewModalTab,
  ReviewModalVariant,
} from "./DraftsAndPublished/Components/ReviewModal";
import { useStateFunction } from "../utils/hooks/useData";
import {
  MdCheck,
  MdClose,
  MdDelete,
  MdFacebook,
  MdNotificationImportant,
  MdRefresh,
  MdRemoveRedEye,
  MdSmsFailed,
} from "react-icons/md";
import { Form } from "react-bootstrap";
import { MetaLambdaFetch } from "../utils/fetch-utils";
import { useSelector } from "react-redux";
import { fullNameSelector, emailSelector } from "../redux/user";
import AlertDialog, { AlertDialogVariants } from "../Components/AlertDialog/AlertDialog";

export const SELECT_ROW = false;
export const DESELECT_ROW = true;

// SELECT ROW
enum LEVEL_HEIRARCHY {
  campaign = 0,
  adset = 1,
  ad = 2,
}
interface SelectRowParams {
  setSelectedRows: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, MetaBuyingTableRow>>>
  >;
  selectedLevel: string;
}
export const useSelectRow = ({
  setSelectedRows,
  selectedLevel,
}: SelectRowParams): ((row: MetaBuyingTableRow, remove?: boolean) => void) => {
  const updateDownstreamSelections = useCallback(
    (selections: Record<string, Record<string, MetaBuyingTableRow>>) => {
      if (!R.isEmpty(selections[selectedLevel])) {
        // If current level has no selections, no need to update downstream selections.
        // If current level has selections, update downstream selections to only include
        // rows that contain the id of rows in the current level.
        const selectedLevelObj = selections[selectedLevel];
        for (let i = LEVEL_HEIRARCHY[selectedLevel] + 1; i < 3; i++) {
          const subLevel = LEVEL_HEIRARCHY[i];
          const subLevelObj = selections[subLevel];
          for (let row of Object.values(subLevelObj)) {
            if (!R.has(row[`${selectedLevel}_id`], selectedLevelObj)) {
              delete subLevelObj[row.id];
            }
          }
        }
      }

      return selections;
    },
    [selectedLevel]
  );

  return useCallback(
    (row: MetaBuyingTableRow, remove?: boolean) => {
      const { id } = row;

      setSelectedRows(current => {
        let updatedSelections: Record<string, Record<string, MetaBuyingTableRow>> = { ...current };

        if ((R.has(id, current[selectedLevel]) && remove === undefined) || remove) {
          // Removing the row from selectedRows
          const currLevelObj = current[selectedLevel];
          const newObj = R.omit([id], currLevelObj);
          updatedSelections = { ...current, [selectedLevel]: newObj };
        } else if ((!R.has(id, current[selectedLevel]) && remove === undefined) || !remove) {
          // Adding the row to selectedRows
          const currLevelObj: { [id: string]: MetaBuyingTableRow } = current[selectedLevel];
          const newObj = { ...currLevelObj, [id]: row };
          updatedSelections = { ...current, [selectedLevel]: newObj };
        }

        // Update downstream selections
        return updateDownstreamSelections(updatedSelections);
      });
    },
    [setSelectedRows, selectedLevel, updateDownstreamSelections]
  );
};

// TABLE HEADERS RENDERER
interface TableHeadersRendererParams {
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
  setSelectedRows: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, MetaBuyingTableRow>>>
  >;
  selectAll: Record<string, boolean>;
  setSelectAll: React.Dispatch<React.SetStateAction<Record<string, boolean>>>;
  selectedLevel: string;
  filteredTableData: MetaBuyingTableRow[];
}
export const useTableHeadersRenderer = ({
  filteredTableData,
  selectAll,
  setSelectAll,
  selectedRows,
  setSelectedRows,
  selectedLevel,
}: TableHeadersRendererParams): (({ data, columnIndex }: any) => JSX.Element) => {
  return useCallback(
    ({ data, columnIndex }) => {
      switch (columnIndex) {
        case 0:
          return (
            <CheckBox
              className="metaBuyingCheckbox"
              checked={selectAll[selectedLevel]}
              onCheck={() => {
                if (selectAll[selectedLevel]) {
                  setSelectedRows({ ...selectedRows, [selectedLevel]: {} });
                } else {
                  let selectAllMap = { [selectedLevel]: {} };

                  for (let row of filteredTableData) {
                    selectAllMap[selectedLevel][row.id] = row;
                  }

                  setSelectedRows(current => ({ ...current, ...selectAllMap }));
                }
                setSelectAll({ ...selectAll, [selectedLevel]: !selectAll[selectedLevel] });
              }}
            />
          );
        default:
          return <div>{data}</div>;
      }
    },
    [filteredTableData, selectAll, setSelectAll, selectedRows, setSelectedRows, selectedLevel]
  );
};

// FILTER BAR
interface FilterBarParams {
  pageTab: "drafts" | "published";
  tableData: any[];
  isInternal: boolean;
  selectedLevel: string;
  setFilter: (func: (line: any) => boolean) => void;
  tokens: FilterBarTokens;
  setTokens: React.Dispatch<React.SetStateAction<FilterBarTokens>>;
}
export const useFilterBar = ({
  pageTab,
  tableData,
  isInternal,
  selectedLevel,
  setFilter,
  tokens,
  setTokens,
}: FilterBarParams): JSX.Element => {
  return useMemo(() => {
    let filterOptions: {
      label: string;
      name: string;
    }[] = [];

    // Set filter options based on user type, selected level, and page tab
    if (!isInternal) {
      filterOptions = [
        { label: "Ad", name: "ad_name" },
        { label: "Ad Set", name: "adset_name" },
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
      ];
      if (pageTab === "published") {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    } else if (selectedLevel === "ad") {
      filterOptions = [
        { label: "Ad", name: "ad_name" },
        { label: "Ad Set", name: "adset_name" },
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
        { label: "Creator", name: "lastuser" },
      ];
      if (pageTab === "drafts") {
        filterOptions.push(
          { label: "Client-Facing", name: "client_facing" },
          { label: "Approval Status", name: "approval_stage" }
        );
      } else {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    } else if (selectedLevel === "adset") {
      filterOptions = [
        { label: "Ad Set", name: "adset_name" },
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
        { label: "Creator", name: "lastuser" },
      ];
      if (pageTab === "published") {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    } else {
      filterOptions = [
        { label: "Campaign", name: "campaign_name" },
        { label: "Created On", name: "created" },
        { label: "Creator", name: "lastuser" },
      ];
      if (pageTab === "published") {
        filterOptions.push({ label: "Status", name: "adsmanager_status" });
      }
    }

    return (
      <FilterBar
        width={1034}
        hasAdvanced={true}
        lines={tableData}
        onFilter={setFilter}
        options={filterOptions}
        tokens={tokens}
        onChange={setTokens}
      />
    );
  }, [isInternal, selectedLevel, tableData, setFilter, pageTab, tokens, setTokens]);
};

// TABLE DATA
export interface TableDataParams {
  isInternal: boolean;
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  adRows: AdRow[];
}
export interface UseTableData {
  setFilter: (func: (line: any) => boolean) => void;
  selectedLevel: string;
  setSelectedLevel: React.Dispatch<React.SetStateAction<string>>;
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
  setSelectedRows: Dispatch<SetStateAction<Record<string, Record<string, MetaBuyingTableRow>>>>;
  selectAll: Record<string, boolean>;
  setSelectAll: Dispatch<SetStateAction<Record<string, boolean>>>;
  tableData: MetaBuyingTableRow[];
  filteredTableData: MetaBuyingTableRow[];
}
export const useTableData = ({
  isInternal,
  campaignRows,
  adSetRows,
  adRows,
}: TableDataParams): UseTableData => {
  const [filter, setFilter] = useStateFunction<(line) => boolean>(() => true);
  const [selectedLevel, setSelectedLevel] = useState<string>(isInternal ? "campaign" : "ad");
  const [selectedRows, setSelectedRows] = useState<
    Record<string, Record<string, MetaBuyingTableRow>>
  >({
    campaign: {},
    adset: {},
    ad: {},
  });
  const [selectAll, setSelectAll] = useState<Record<string, boolean>>({
    campaign: false,
    adset: false,
    ad: false,
  });

  // Map of each level to its rows. Data is filtered based on selected rows.
  const viewableDataMap: Record<string, MetaBuyingTableRow[]> = useMemo(() => {
    let viewableAds: AdRow[] = adRows;
    if (!R.isEmpty(selectedRows.campaign)) {
      viewableAds = adRows.filter(row => {
        return R.has(row.campaign_id, selectedRows.campaign);
      });
    }
    if (!R.isEmpty(selectedRows.adset)) {
      viewableAds = viewableAds.filter(row => {
        return R.has(row.adset_id, selectedRows.adset);
      });
    }

    const viewableAdSets: AdSetRow[] = R.isEmpty(selectedRows.campaign)
      ? adSetRows
      : adSetRows.filter(row => {
          return R.has(row.campaign_id, selectedRows.campaign);
        });

    return {
      campaign: campaignRows,
      adset: viewableAdSets,
      ad: viewableAds,
    };
  }, [adRows, adSetRows, campaignRows, selectedRows]);

  // Update selectAll state when viewableDataMap changes
  useEffect(() => {
    for (let level of Object.keys(selectedRows)) {
      const currObj = selectedRows[level];
      if (Object.keys(currObj).length === viewableDataMap[level].length) {
        setSelectAll(current => ({ ...current, [level]: true }));
      } else {
        setSelectAll(current => ({ ...current, [level]: false }));
      }
    }
  }, [selectedRows, setSelectAll, viewableDataMap]);

  // Rows to display in the table based on selected level
  const tableData: MetaBuyingTableRow[] = useMemo(() => {
    if (selectedLevel === "ad") {
      return viewableDataMap.ad;
    } else if (selectedLevel === "adset") {
      return viewableDataMap.adset;
    } else {
      return viewableDataMap.campaign;
    }
  }, [selectedLevel, viewableDataMap]);

  // Apply filter bar to table data
  const filteredTableData: MetaBuyingTableRow[] = useMemo(() => {
    return tableData?.filter(filter) || [];
  }, [tableData, filter]);

  return {
    setFilter,
    selectedLevel,
    setSelectedLevel,
    selectedRows,
    setSelectedRows,
    selectAll,
    setSelectAll,
    tableData,
    filteredTableData,
  };
};

// TABLE COMPONENT
interface TableParams {
  className: string;
  filteredTableData: MetaBuyingTableRow[];
  columns: Header[];
  headersRenderer: ({ data, columnIndex }: any) => JSX.Element;
}
export const useMetaBuyingTable = ({
  className,
  filteredTableData,
  columns,
  headersRenderer,
}: TableParams): JSX.Element => {
  return useMemo(() => {
    return (
      <BPMTable
        className={className}
        alternateColors={false}
        data={filteredTableData}
        headers={columns}
        headersRenderer={headersRenderer}
        filterBar={false}
        noRowsRenderer={() => <div>No rows to show.</div>}
      ></BPMTable>
    );
  }, [className, filteredTableData, columns, headersRenderer]);
};

// MORE ACTIONS BUTTON POSITION
interface GetButtonPositionParams {
  buttonRefs: React.MutableRefObject<{}>;
  selectedLevel: string;
}
// Returns the position of the more actions button based on the rowId
export const useGetButtonPosition = ({
  buttonRefs,
  selectedLevel,
}: GetButtonPositionParams): ((
  rowId: any
) => {
  top: number;
  left: number;
}) => {
  return useCallback(
    rowId => {
      const rect = buttonRefs.current[rowId]?.getBoundingClientRect();
      const menuHeight = selectedLevel === "ad" ? 190 : 156;
      const menuWidth = selectedLevel === "ad" ? 167 : 138;
      const buttonHeight = 28;
      return rect
        ? {
            top:
              window.innerHeight - rect.top > menuHeight
                ? rect.top
                : rect.top - menuHeight + buttonHeight,
            left: rect.left - menuWidth,
          }
        : { top: 0, left: 0 };
    },
    [buttonRefs, selectedLevel]
  );
};

// ALERT DIALOG
export enum MBAlertDialogType {
  CLIENT_FACING_ENABLED = "CLIENT_FACING_ENABLED",
  CLIENT_FACING_DISABLED = "CLIENT_FACING_DISABLED",
  REQUEST_APPROVAL = "REQUEST_APPROVAL",
  PUBLISH_AS_PAUSED = "PUBLISH_AS_PAUSED",
  APPROVED = "APPROVED",
  CHANGES_REQUESTED = "CHANGES_REQUESTED",
  STATUS_REMOVED = "STATUS_REMOVED",
  DELETE = "DELETE",
}
interface AlertDialogManagerParams {
  isInternal: boolean;
  selectedLevel: string;
  selectedRows: Record<string, Record<string, MetaBuyingTableRow>>;
  setSelectedRows: React.Dispatch<
    React.SetStateAction<Record<string, Record<string, MetaBuyingTableRow>>>
  >;
  selectRow: (row: MetaBuyingTableRow, remove?: boolean) => void;
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  adRows: AdRow[];
}
export interface UseAlertDialog {
  alertDialogType: MBAlertDialogType | undefined;
  alertDialogActivatedRow: MetaBuyingTableRow | undefined;
  openAlertDialog: (type: MBAlertDialogType, row?: MetaBuyingTableRow) => void;
  alertDialogComponent: JSX.Element | undefined;
}
export const useAlertDialog = ({
  isInternal,
  selectedLevel,
  selectedRows,
  setSelectedRows,
  selectRow,
  campaignRows,
  adSetRows,
  adRows,
}: AlertDialogManagerParams): UseAlertDialog => {
  const [alertDialogType, setAlertDialogType] = useState<MBAlertDialogType>();
  const [alertDialogActivatedRow, setAlertDialogActivatedRow] = useState<MetaBuyingTableRow>();

  const openAlertDialog = useCallback((type: MBAlertDialogType, row?: MetaBuyingTableRow) => {
    setAlertDialogType(type);
    setAlertDialogActivatedRow(row);
  }, []);

  const closeAlertDialog = useCallback(() => {
    setAlertDialogType(undefined);
    setAlertDialogActivatedRow(undefined);
  }, []);

  const fullName = useSelector(fullNameSelector);
  const email = useSelector(emailSelector);

  // TODO: Throw error if notes are empty when required
  const updateAdApprovalStage = useCallback(
    async (newStage: MBApprovalStage) => {
      await MetaLambdaFetch("/updateAdApprovalStage", {
        method: "POST",
        body: {
          newStage,
          adRows: Object.values(selectedRows.ad) as AdRow[],
          requester: `${fullName} (${email})`,
          isInternal,
          notes: "Testing",
        },
      });
    },
    [email, fullName, isInternal, selectedRows]
  );

  // Keeps track of notes in alert dialog
  const [notes, setNotes] = useState<string>("");

  const {
    alertDialogVariant,
    alertDialogTitle,
    alertDialogIcon,
    alertDialogBody,
    alertDialogPrimaryAction,
    alertDialogPrimaryLabel,
    alertDialogSecondaryAction,
    alertDialogSecondaryLabel,
  } = useMemo(() => {
    const currentLevelSelections = Object.values(selectedRows[selectedLevel]);
    let alertDialogVariant,
      alertDialogTitle,
      alertDialogIcon,
      alertDialogBody,
      alertDialogPrimaryAction,
      alertDialogPrimaryLabel,
      alertDialogSecondaryAction,
      alertDialogSecondaryLabel;

    // Set variables based on alert dialog type
    if (alertDialogType === MBAlertDialogType.CLIENT_FACING_ENABLED && alertDialogActivatedRow) {
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Your client can now see the following ad(s):";
      alertDialogIcon = <MdRemoveRedEye size={72} />;
      alertDialogBody = adNames;
      // TODO: revisit logic for primary action
      alertDialogPrimaryAction = () => {
        console.log("Client facing enabled");
        setSelectedRows(current => {
          return { ...current, ad: {} };
        });
        closeAlertDialog();
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Undo";
    } else if (
      alertDialogType === MBAlertDialogType.CLIENT_FACING_DISABLED &&
      alertDialogActivatedRow
    ) {
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Your client can no longer see the following ad(s):";
      alertDialogIcon = <MdRemoveRedEye size={72} />;
      alertDialogBody = adNames;
      // TODO: revisit logic for primary action
      alertDialogPrimaryAction = () => {
        console.log("Client facing disabled");
        setSelectedRows(current => {
          return { ...current, ad: {} };
        });
        closeAlertDialog();
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Undo";
    } else if (alertDialogType === MBAlertDialogType.REQUEST_APPROVAL && alertDialogActivatedRow) {
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Request approval for the following ad(s):";
      alertDialogIcon = <MdNotificationImportant size={72} />;
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionText">{adNames}</div>
          </div>
          <Form.Group>
            <Form.Label className="inputFieldFormLabel">{"Notes (optional):"}</Form.Label>
            <Form.Control
              className="inputFieldFormControl"
              as="textarea"
              value={notes}
              placeholder="Anything you'd like to say?"
              onChange={e => {
                setNotes(e.target.value);
              }}
            />
          </Form.Group>
        </div>
      );
      alertDialogPrimaryAction = () => {
        setSelectedRows(current => {
          return { ...current, ad: {} };
        });
        updateAdApprovalStage(MBApprovalStage.IN_REVIEW);
        setNotes("");
        closeAlertDialog();
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        setNotes("");
        closeAlertDialog();
      };
    } else if (alertDialogType === MBAlertDialogType.PUBLISH_AS_PAUSED) {
      let campaignRowsToPublish: { [id: string]: CampaignRow } = alertDialogActivatedRow
        ? {}
        : ({ ...selectedRows.campaign } as {
            [id: string]: CampaignRow;
          });
      let adSetRowsToPublish: { [id: string]: AdSetRow } = alertDialogActivatedRow
        ? {}
        : ({ ...selectedRows.adset } as {
            [id: string]: AdSetRow;
          });
      let adRowsToPublish: { [id: string]: AdRow } = alertDialogActivatedRow
        ? {}
        : ({ ...selectedRows.ad } as {
            [id: string]: AdRow;
          });

      let campaignNames = "",
        adSetNames = "",
        adNames = "",
        descriptionText: JSX.Element;
      /*
       * If alert dialog activated row is present, we are publishing that row
       * and any upstream rows. Otherwise, we take into account all selected
       * rows in else block.
       */
      if (alertDialogActivatedRow && selectedLevel === "ad") {
        adNames = (alertDialogActivatedRow as AdRow).ad_name;
        // Looking for ad set that shares adset id with ad selection
        adSetRows.forEach(adSetRow => {
          if (adSetRow.id === (alertDialogActivatedRow as AdRow).adset_id) {
            adSetNames = adSetRow.adset_name;
          }
        });
        // Looking for campaign that shares campaign id with ad selection
        campaignRows.forEach(campaignRow => {
          if (campaignRow.id === (alertDialogActivatedRow as AdRow).campaign_id) {
            campaignNames = campaignRow.campaign_name;
          }
        });
      } else if (alertDialogActivatedRow && selectedLevel === "adset") {
        adSetNames = (alertDialogActivatedRow as AdSetRow).adset_name;
        // Looking for campaign that shares campaign id with ad set selection
        campaignRows.forEach(campaignRow => {
          if (campaignRow.id === (alertDialogActivatedRow as AdSetRow).campaign_id) {
            campaignNames = campaignRow.campaign_name;
          }
        });
      } else if (alertDialogActivatedRow && selectedLevel === "campaign") {
        campaignNames = (alertDialogActivatedRow as CampaignRow).campaign_name;
      } else {
        const adRowsToPublishArr = Object.values(adRowsToPublish) as AdRow[];
        adNames = adRowsToPublishArr.map(row => row.ad_name).join(", ");

        // Looking for ad set(s) that share ad set id with ad selection(s)
        adRowsToPublishArr.forEach(adRow => {
          adSetRows.forEach(adSetRow => {
            if (adSetRow.id === adRow.adset_id) {
              adSetRowsToPublish[adSetRow.id] = adSetRow;
            }
          });
        });
        const adSetRowsToPublishArr = Object.values(adSetRowsToPublish) as AdSetRow[];
        adSetNames = adSetRowsToPublishArr.map(row => row.adset_name).join(", ");

        // Looking for campaign(s) that share campaign id with ad set selection(s)
        adSetRowsToPublishArr.forEach(adSetRow => {
          campaignRows.forEach(campaignRow => {
            if (campaignRow.id === adSetRow.campaign_id) {
              campaignRowsToPublish[campaignRow.id] = campaignRow;
            }
          });
        });
        campaignNames = Object.values(campaignRowsToPublish)
          .map(row => row.campaign_name)
          .join(", ");
      }

      descriptionText = (
        <>
          {adNames && <div>{`ad(s): ${adNames}`}</div>}
          {adNames && adSetNames && <br />}
          {adSetNames && <div>{`ad set(s): ${adSetNames}`}</div>}
          {adSetNames && campaignNames && <br />}
          {campaignNames && <div>{`campaign(s): ${campaignNames}`}</div>}
        </>
      );

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Published to Meta as paused!";
      alertDialogIcon = <MdFacebook size={72} />;
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionHeader">
              Go to Ads Manager to complete the publishing process.
            </div>
            <div className="inputFieldDescriptionText">{descriptionText}</div>
          </div>
        </div>
      );
      alertDialogPrimaryAction = () => {
        /*
          TODO: Add lambda for publishing as paused
        */
        console.log("Published as paused");
        setSelectedRows(current => {
          return { ...current, [selectedLevel]: {} };
        });
        closeAlertDialog();
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        if (alertDialogActivatedRow) {
          selectRow(alertDialogActivatedRow, DESELECT_ROW);
        }
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Cancel";
    } else if (alertDialogType === MBAlertDialogType.APPROVED && alertDialogActivatedRow) {
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Approved!";
      alertDialogIcon = <MdCheck size={72} />;
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionHeader">
              Your team will be notified that this ad is approved:
            </div>
            <div className="inputFieldDescriptionText">{adNames}</div>
          </div>
          <Form.Group>
            <Form.Label className="inputFieldFormLabel">{"Notes (optional):"}</Form.Label>
            <Form.Control
              className="inputFieldFormControl"
              as="textarea"
              value={notes}
              placeholder="Anything you'd like to say?"
              onChange={e => {
                setNotes(e.target.value);
              }}
            />
          </Form.Group>
        </div>
      );
      alertDialogPrimaryAction = () => {
        setSelectedRows(current => {
          return { ...current, ad: {} };
        });
        updateAdApprovalStage(MBApprovalStage.APPROVED);
        setNotes("");
        closeAlertDialog();
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        setNotes("");
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Cancel";
    } else if (alertDialogType === MBAlertDialogType.CHANGES_REQUESTED && alertDialogActivatedRow) {
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "We've flagged this ad as 'Not Approved'";
      alertDialogIcon = <MdClose size={72} style={{ color: "#C0001C" }} />;
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionHeader">
              Your team will be notified this ad is not approved:
            </div>
            <div className="inputFieldDescriptionText">{adNames}</div>
          </div>
          <Form.Group>
            <Form.Label className="inputFieldFormLabel">
              {"Tell us why this is not approved (required):"}
            </Form.Label>
            <Form.Control
              className="inputFieldFormControl"
              as="textarea"
              value={notes}
              placeholder="For example: 'Incorrect copy, please fix typo'"
              onChange={e => {
                setNotes(e.target.value);
              }}
            />
          </Form.Group>
        </div>
      );
      alertDialogPrimaryAction = () => {
        setSelectedRows(current => {
          return { ...current, ad: {} };
        });
        updateAdApprovalStage(MBApprovalStage.CHANGES_REQUESTED);
        setNotes("");
        closeAlertDialog();
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        setNotes("");
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Cancel";
    } else if (alertDialogType === MBAlertDialogType.STATUS_REMOVED && alertDialogActivatedRow) {
      const adNames = currentLevelSelections.map(ad => (ad as AdRow).ad_name).join(", ");

      alertDialogVariant = AlertDialogVariants.INFO;
      alertDialogTitle = "Approval status removed";
      alertDialogIcon = <MdRefresh size={72} />;
      alertDialogBody = (
        <div className="inputFieldBody">
          <div className="inputFieldDescription">
            <div className="inputFieldDescriptionHeader">
              Your team will be notified that this ad is now awaiting approval:
            </div>
            <div className="inputFieldDescriptionText">{adNames}</div>
          </div>
        </div>
      );
      alertDialogPrimaryAction = () => {
        setSelectedRows(current => {
          return { ...current, ad: {} };
        });
        updateAdApprovalStage(MBApprovalStage.IN_REVIEW);
        closeAlertDialog();
      };
      alertDialogPrimaryLabel = "Got it!";
      alertDialogSecondaryAction = () => {
        selectRow(alertDialogActivatedRow, DESELECT_ROW);
        closeAlertDialog();
      };
      alertDialogSecondaryLabel = "Cancel";
    } else if (alertDialogType === MBAlertDialogType.DELETE) {
      let campaignRowsToDelete: { [id: string]: CampaignRow } = alertDialogActivatedRow
        ? {}
        : ({ ...selectedRows.campaign } as {
            [id: string]: CampaignRow;
          });
      let adSetRowsToDelete: { [id: string]: AdSetRow } = alertDialogActivatedRow
        ? {}
        : ({ ...selectedRows.adset } as {
            [id: string]: AdSetRow;
          });
      let adRowsToDelete: { [id: string]: AdRow } = alertDialogActivatedRow
        ? {}
        : ({ ...selectedRows.ad } as {
            [id: string]: AdRow;
          });

      let campaignNames = "",
        adSetNames = "",
        adNames = "",
        descriptionText: JSX.Element;
      /*
       * If alert dialog activated row is present, we are deleting that row
       * and any downstream rows. Otherwise, we take into account all selected
       * rows in else block.
       */
      if (alertDialogActivatedRow && selectedLevel === "ad") {
        adNames = (alertDialogActivatedRow as AdRow).ad_name;
      } else if (alertDialogActivatedRow && selectedLevel === "adset") {
        adSetNames = (alertDialogActivatedRow as AdSetRow).adset_name;
        // Looking for ad(s) that share ad set id with ad set selection
        adRows.forEach(adRow => {
          if (adRow.adset_id === (alertDialogActivatedRow as AdSetRow).id) {
            adRowsToDelete[adRow.id] = adRow;
          }
        });
        adNames = Object.values(adRowsToDelete)
          .map(row => row.ad_name)
          .join(", ");
      } else if (alertDialogActivatedRow && selectedLevel === "campaign") {
        campaignNames = (alertDialogActivatedRow as CampaignRow).campaign_name;
        // Looking for ad sets that share campaign id with campaign selection
        adSetRows.forEach(adSetRow => {
          if (adSetRow.campaign_id === (alertDialogActivatedRow as CampaignRow).id) {
            adSetRowsToDelete[adSetRow.id] = adSetRow;
          }
        });
        const adSetRowsToDeleteArr = Object.values(adSetRowsToDelete);
        adSetNames = adSetRowsToDeleteArr.map(row => row.adset_name).join(", ");
        // Looking for ads that share ad set id with ad sets to delete
        adSetRowsToDeleteArr.forEach(adSetRow => {
          adRows.forEach(adRow => {
            if (adRow.adset_id === adSetRow.id) {
              adRowsToDelete[adRow.id] = adRow;
            }
          });
        });
        adNames = Object.values(adRowsToDelete)
          .map(row => row.ad_name)
          .join(", ");
      } else {
        const campaignRowsToDeleteArr = Object.values(campaignRowsToDelete) as CampaignRow[];
        campaignNames = campaignRowsToDeleteArr.map(row => row.campaign_name).join(", ");
        // Looking for ad sets that share campaign id with campaign selection(s)
        campaignRowsToDeleteArr.forEach(row => {
          adSetRows.forEach(adSetRow => {
            if (adSetRow.campaign_id === row.id) {
              adSetRowsToDelete[adSetRow.id] = adSetRow;
            }
          });
        });
        const adSetRowsToDeleteArr = Object.values(adSetRowsToDelete) as AdSetRow[];
        adSetNames = adSetRowsToDeleteArr.map(row => row.adset_name).join(", ");
        // Looking for ad(s) that share ad set id with ad sets to delete
        adSetRowsToDeleteArr.forEach(adSetRow => {
          adRows.forEach(adRow => {
            if (adRow.adset_id === adSetRow.id) {
              adRowsToDelete[adRow.id] = adRow;
            }
          });
        });
        adNames = Object.values(adRowsToDelete)
          .map(row => row.ad_name)
          .join(", ");
      }
      descriptionText = (
        <>
          {adNames && <div>{`ad(s): ${adNames}`}</div>}
          {adNames && adSetNames && <br />}
          {adSetNames && <div>{`ad set(s): ${adSetNames}`}</div>}
          {adSetNames && campaignNames && <br />}
          {campaignNames && <div>{`campaign(s): ${campaignNames}`}</div>}
        </>
      );

      const adRowsToDeleteArr = Object.values(adRowsToDelete) as AdRow[];
      const adSetRowsToDeleteArr = Object.values(adSetRowsToDelete) as AdSetRow[];
      const campaignRowsToDeleteArr = Object.values(campaignRowsToDelete) as CampaignRow[];
      // Will show a different deletion alert dialog based on whether the selected
      // level has children
      let hasChildren: boolean;
      if (alertDialogActivatedRow && selectedLevel === "ad") {
        hasChildren = false;
      } else if (alertDialogActivatedRow && selectedLevel === "adset") {
        hasChildren = adRowsToDeleteArr.length > 0;
      } else if (alertDialogActivatedRow && selectedLevel === "campaign") {
        hasChildren = adSetRowsToDeleteArr.length > 0;
      } else {
        hasChildren =
          (campaignRowsToDeleteArr.length > 0 && adSetRowsToDeleteArr.length > 0) ||
          (adSetRowsToDeleteArr.length > 0 && adRowsToDeleteArr.length > 0);
      }
      if (!hasChildren) {
        alertDialogVariant = AlertDialogVariants.WARNING;
        alertDialogTitle = `Delete ${selectedLevel}?`;
        alertDialogIcon = <MdDelete size={72} />;
        alertDialogBody = (
          <div className="inputFieldBody">
            <div className="inputFieldDescription">
              <div className="inputFieldDescriptionHeader">You won't be able to undo this.</div>
              <div className="inputFieldDescriptionText">{descriptionText}</div>
            </div>
          </div>
        );
        alertDialogPrimaryAction = () => {
          console.log("Row deleted");
          /*
            TODO: Add lambda for deleting selections
          */
          if (alertDialogActivatedRow) {
            selectRow(alertDialogActivatedRow, DESELECT_ROW);
          }
          closeAlertDialog();
        };
        alertDialogPrimaryLabel = "Delete";
        alertDialogSecondaryAction = () => {
          if (alertDialogActivatedRow) {
            selectRow(alertDialogActivatedRow, DESELECT_ROW);
          }
          closeAlertDialog();
        };
        alertDialogSecondaryLabel = "Cancel";
      } else {
        alertDialogVariant = AlertDialogVariants.ERROR;
        const innerText =
          selectedLevel === "campaign"
            ? `ad sets${adRowsToDeleteArr.length ? " and ads" : ""}`
            : "ads";
        alertDialogTitle = `This will delete all of the ${innerText} within this ${selectedLevel}!`;
        alertDialogIcon = <MdSmsFailed size={72} />;
        alertDialogBody = (
          <div className="inputFieldBody">
            <div className="inputFieldDescription">
              <div className="inputFieldDescriptionHeader">You won't be able to undo this.</div>
              <div className="inputFieldDescriptionText">{descriptionText}</div>
            </div>
          </div>
        );
        alertDialogPrimaryAction = () => {
          console.log("Rows deleted");
          /*
            TODO: Add lambda for deleting selections and any downstream rows
            that may be stored in adSetRowsToDelete and adRowsToDelete
          */
          if (alertDialogActivatedRow) {
            selectRow(alertDialogActivatedRow, DESELECT_ROW);
          }
          closeAlertDialog();
        };
        alertDialogPrimaryLabel = "Delete";
        alertDialogSecondaryAction = () => {
          if (alertDialogActivatedRow) {
            selectRow(alertDialogActivatedRow, DESELECT_ROW);
          }
          closeAlertDialog();
        };
        alertDialogSecondaryLabel = "Cancel";
      }
    }

    return {
      alertDialogVariant,
      alertDialogTitle,
      alertDialogIcon,
      alertDialogBody,
      alertDialogPrimaryAction,
      alertDialogPrimaryLabel,
      alertDialogSecondaryAction,
      alertDialogSecondaryLabel,
    };
  }, [
    adRows,
    adSetRows,
    alertDialogActivatedRow,
    alertDialogType,
    campaignRows,
    closeAlertDialog,
    notes,
    selectRow,
    selectedLevel,
    selectedRows,
    setSelectedRows,
    updateAdApprovalStage,
  ]);

  const alertDialogComponent = useMemo(() => {
    return (
      alertDialogType && (
        <AlertDialog
          variant={alertDialogVariant}
          className="mbAlertDialog"
          show={true}
          onHide={() => {
            if (alertDialogActivatedRow) {
              selectRow(alertDialogActivatedRow, DESELECT_ROW);
            }
            closeAlertDialog();
          }}
          icon={alertDialogIcon}
          title={alertDialogTitle}
          body={alertDialogBody}
          primaryAction={alertDialogPrimaryAction}
          primaryButtonLabel={alertDialogPrimaryLabel}
          secondaryAction={alertDialogSecondaryAction}
          secondaryButtonLabel={alertDialogSecondaryLabel}
        />
      )
    );
  }, [
    alertDialogActivatedRow,
    alertDialogBody,
    alertDialogIcon,
    alertDialogPrimaryAction,
    alertDialogPrimaryLabel,
    alertDialogSecondaryAction,
    alertDialogSecondaryLabel,
    alertDialogTitle,
    alertDialogType,
    alertDialogVariant,
    closeAlertDialog,
    selectRow,
  ]);

  return {
    alertDialogType,
    alertDialogActivatedRow,
    openAlertDialog,
    alertDialogComponent,
  };
};

// REVIEW MODAL
interface ReviewModalParams {
  reviewModalVariant: ReviewModalVariant;
  selectedLevel: string;
  campaignRows: CampaignRow[];
  adSetRows: AdSetRow[];
  isInternal: boolean;
  selectRow: (row: MetaBuyingTableRow, remove?: boolean) => void;
  openAlertDialog?: (type: MBAlertDialogType, row?: MetaBuyingTableRow) => void;
}
export interface UseReviewModal {
  setReviewModalActivatedRow: React.Dispatch<React.SetStateAction<MetaBuyingTableRow | undefined>>;
  reviewModalSetSelectedTab: React.Dispatch<React.SetStateAction<ReviewModalTab>>;
  reviewModalComponent: JSX.Element | undefined;
}
export const useReviewModal = ({
  reviewModalVariant,
  selectedLevel,
  campaignRows,
  adSetRows,
  isInternal,
  selectRow,
  openAlertDialog,
}: ReviewModalParams): UseReviewModal => {
  const [reviewModalActivatedRow, setReviewModalActivatedRow] = useState<MetaBuyingTableRow>();
  const [reviewModalSelectedTab, reviewModalSetSelectedTab] = useState<ReviewModalTab>(
    ReviewModalTab.NONE
  );

  const { reviewModalCampaign, reviewModalAdSet, reviewModalAd } = useMemo(() => {
    return {
      /*
        Set campaign based on selected level. If level is ad and user is internal,
        choose campaign based on ad's campaign_id. If level is adset and user is internal,
        choose campaign based on adset's campaign_id.
      */
      reviewModalCampaign:
        selectedLevel === "campaign" ? (reviewModalActivatedRow as CampaignRow) : undefined,
      /*
        Set adset based on selected level. If level is ad and user is internal,
        choose ad set based on ad's adset_id.
      */
      reviewModalAdSet:
        selectedLevel === "adset" ? (reviewModalActivatedRow as AdSetRow) : undefined,
      // Set ad based on selected level.
      reviewModalAd: selectedLevel === "ad" ? (reviewModalActivatedRow as AdRow) : undefined,
    };
  }, [reviewModalActivatedRow, selectedLevel]);

  const reviewModalComponent = useMemo(() => {
    return (
      reviewModalActivatedRow && (
        <ReviewModal
          isInternal={isInternal}
          variant={reviewModalVariant}
          selectedTab={reviewModalSelectedTab}
          setSelectedTab={reviewModalSetSelectedTab}
          onHide={() => {
            setReviewModalActivatedRow(undefined);
            selectRow(reviewModalActivatedRow, DESELECT_ROW);
          }}
          campaign={reviewModalCampaign}
          adset={reviewModalAdSet}
          ad={reviewModalAd}
          approve={() => {
            if (reviewModalVariant === ReviewModalVariant.DRAFT && openAlertDialog) {
              openAlertDialog(MBAlertDialogType.APPROVED, reviewModalActivatedRow);
              setReviewModalActivatedRow(undefined);
            }
          }}
          publishAsPaused={() => {
            // TODO: update logic to handle publishing as paused
            if (reviewModalVariant === ReviewModalVariant.DRAFT && openAlertDialog) {
              openAlertDialog(MBAlertDialogType.PUBLISH_AS_PAUSED, reviewModalActivatedRow);
              setReviewModalActivatedRow(undefined);
            }
          }}
          remove={() => {
            if (reviewModalVariant === ReviewModalVariant.DRAFT && openAlertDialog) {
              openAlertDialog(MBAlertDialogType.DELETE, reviewModalActivatedRow);
              setReviewModalActivatedRow(undefined);
            }
          }}
        />
      )
    );
  }, [
    reviewModalActivatedRow,
    isInternal,
    reviewModalVariant,
    reviewModalSelectedTab,
    reviewModalCampaign,
    reviewModalAdSet,
    reviewModalAd,
    selectRow,
    openAlertDialog,
  ]);

  return {
    setReviewModalActivatedRow,
    reviewModalSetSelectedTab,
    reviewModalComponent,
  };
};

// MESSAGE ICON
interface MessageIconProps {
  color: string;
}
export const MessageIcon = ({ color }: MessageIconProps): JSX.Element => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path
        d="M15.3897 2H2.58454C1.70853 2 1 2.70853 1 3.59742V18L4.19485 14.8052H15.3897C16.2786 14.8052 17 14.0709 17 13.2077V3.59742C17 2.70853 16.2786 2 15.3897 2ZM9.37359 13.6457C8.71659 13.6457 8.2657 13.1562 8.2657 12.525C8.2657 11.8937 8.72947 11.3913 9.37359 11.3913C10.0564 11.3913 10.4815 11.868 10.4815 12.525C10.4815 13.182 10.0564 13.6457 9.37359 13.6457ZM9.91465 10.5539H8.781L7.80193 3.26248H10.8422L9.91465 10.5539Z"
        fill={color}
      />
    </svg>
  );
};

const { CLIENT, AGENCY } = DimensionType;

export const selectionsToArray = (selections: Record<string, boolean>): string[] =>
  R.keys(R.pickBy((val: boolean) => val, selections));

export const dimensionToFieldName = (dimension: string): string =>
  `${dimension.charAt(0).toUpperCase()}${dimension.slice(1)}`.replace("_", " ");

export const filterSegmentationIds = (
  nameFields: Record<string, any>,
  dimensions: Record<string, any>,
  segmentationDimensionId?: string | number
): string[] =>
  segmentationDimensionId
    ? R.keys(
        R.filter(
          (segments: Record<string, any>) =>
            R.all(key => segments[key] === nameFields[key], R.keys(segments)),
          dimensions[segmentationDimensionId].options
        )
      )
    : [];

export const generateNameFields = (
  namingConvention: number[],
  allNameFields: Record<string, any>,
  dimensions: Record<string, any>,
  client?: string,
  agency?: string
): Record<string, any> => {
  let fields = {};
  namingConvention.forEach(dimensionId => {
    const dimensionType = dimensions[dimensionId].type;
    let { value } = allNameFields[dimensionId];
    if (client && dimensionType === CLIENT) {
      value = client;
    } else if (agency && dimensionType === AGENCY) {
      value = agency;
    }
    fields[dimensionId] = value;
  });
  return fields;
};

export const setNameField = (
  id: string,
  value: string,
  nameFields: Record<string, any>,
  setNameFields: React.Dispatch<React.SetStateAction<Record<string, any>>>
): void => {
  let newNameFields = R.clone(nameFields);
  newNameFields[id] = value;
  setNameFields(newNameFields);
};

export const filterInheritedFields = (fields: Record<string, any>): string[] =>
  R.keys(
    R.filter(
      (field: { value: string; inheritedFrom?: string }) => R.has("inheritedFrom", field),
      fields
    )
  );
