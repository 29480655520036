import { MdOutlineTableRows, MdShowChart } from "react-icons/md";
import { IconToggleButton } from "../Components";
import ChartContainer from "../Components/ChartContainer";
import { useMemo, useState } from "react";
import { Mixpanel, MxE } from "../utils/mixpanelWrapper";
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import {
  CARTESIAN_GRID_STROKE,
  CARTESIAN_GRID_STROKE_WIDTH,
  TICK_STYLE,
} from "../Components/Charts/ChartConstants";
import { Brand60 } from "../utils/colors";
import "./SpendVsChart.scss";

interface SpendVsChartProps {
  variableName: string;
  data: { val: number; spend: number }[];
}

export const SpendVsChart: React.FC<SpendVsChartProps> = ({ variableName, data }) => {
  const [graphView, setGraphView] = useState<boolean>(true);

  const maxVal = useMemo(() => Math.max(...data.map(val => val.val)), [data]);
  const sortedData = useMemo(() => data.sort((a, b) => a.spend - b.spend), [data]);

  return (
    <div className="youTubeSpendVsChart">
      <ChartContainer
        enableHoverDesign
        title={`Spend vs ${variableName}`}
        height={240}
        rightActions={
          <IconToggleButton
            size="sm"
            options={[
              { key: "showTable", icon: <MdOutlineTableRows />, label: "table view" },
              { key: "showGraph", icon: <MdShowChart />, label: "graph view" },
            ]}
            selectedOption={graphView ? "showGraph" : "showTable"}
            onChange={() =>
              setGraphView(prev => {
                Mixpanel.track(MxE.GRAPH_OR_TABLE_CHANGE, {
                  widget: `youtube_plan_spend_vs_${variableName}`,
                  option_selected: !prev ? "graph" : "table",
                });
                return !prev;
              })
            }
          />
        }
      >
        {graphView ? (
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              data={sortedData}
              margin={{
                top: 0,
                right: 24,
                left: -12,
                bottom: -12,
              }}
            >
              <CartesianGrid
                stroke={CARTESIAN_GRID_STROKE}
                strokeWidth={CARTESIAN_GRID_STROKE_WIDTH}
                vertical={false}
              />
              <XAxis dataKey="spend" tick={TICK_STYLE} tickFormatter={val => `$${val}`} />
              <YAxis
                tick={TICK_STYLE}
                tickLine={false}
                axisLine={false}
                width={75}
                type="number"
                domain={[0, maxVal]}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="val"
                dot={false}
                isAnimationActive={false}
                stroke={Brand60}
                strokeWidth={3}
              />
            </LineChart>
          </ResponsiveContainer>
        ) : (
          <div></div>
          // <BPMTable
          //   headers={headers}
          //   data={rows}
          //   totals={totals}
          //   filterBar={false}
          //   headerHeight={30}
          //   bottomRowHeight={30}
          // />
        )}
      </ChartContainer>
    </div>
  );
};

export default SpendVsChart;
