import "./ToplineNumber.scss";

interface ToplineNumberProps {
  label: string;
  value: string;
}

export const ToplineNumber: React.FC<ToplineNumberProps> = ({ label, value }) => {
  return (
    <div className="youTubePlanToplineNumber">
      <div className="youTubePlanToplineNumberLabel">{label}</div>
      <div className="youTubePlanToplineNumberValue">{value}</div>
    </div>
  );
};

export default ToplineNumber;
