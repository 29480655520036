import { Metric } from "@blisspointmedia/bpm-types/dist/CrossChannel";
import { Button, ButtonType, DownloadDropdown, FilterBar } from "../../Components";
import { ButtonFrameworkVariant } from "../../Components/ButtonFramework";
import { CrossChannelContext } from "../CrossChannel";
import { DateRange, StateSetter } from "../../utils/types";
import { download } from "../../utils/download-utils";
import { MdViewHeadline } from "react-icons/md";
import { GLOSSARY, METRIC_TO_PRETTY_NAME } from "../crossChannelConstants";
import { Modal } from "react-bootstrap";
import { ReactComponent as ColorScale } from "../../SingleChannel/colorScale.svg";
import { useCompanyInfo } from "../../redux/company";
import { useMap } from "../../utils/hooks/useData";
import { useSelector } from "react-redux";
import { useSetError } from "../../redux/modals";
import * as CC from "@blisspointmedia/bpm-types/dist/CrossChannelPerformance";
import * as R from "ramda";
import * as UserRedux from "../../redux/user";
import * as uuid from "uuid";
import EditTablePresetModal from "../../SingleChannel/MetricsTableConfig/EditTablePresetModal";
import MetricsTableWithPresets from "../../SingleChannel/MetricsTable/MetricsTableWithPresets";
import PerformanceGridSkeleton from "../../Performance/PerformanceGridSkeleton";
import PresetDropdown from "../../SingleChannel/PresetDropdown";
import RenameModal from "../../SingleChannel/RenameModal";
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  awaitJSON,
  CrossChannelLambdaFetch,
  SingleChannelLambdaFetch,
} from "../../utils/fetch-utils";
import {
  DeletePresetParams as DeleteTablePresetParams,
  DimensionColumn,
  GetPresetParams as GetTablePresetParams,
  MetricsTablePreset,
  PresetIDGroups as SimpleMetricsTablePreset,
  SavePresetParams as SaveTablePresetParams,
  SavePresetResponse as SaveTablePresetResponse,
} from "@blisspointmedia/bpm-types/dist/MetricsTable";
import {
  DimensionMap,
  CellData,
  GlossaryItem,
  ColumnMetaDataMap,
  constructTableData,
  DEFAULT_GET_DIMENSION_CELL,
  getDataHeaders,
  getDimensionDataHeaders,
  sortData,
  getExportedData,
} from "../../SingleChannel/MetricsTable/metricsTableUtils";
import {
  GetKpiMetaDataResponse,
  GetPageDataResponse,
  PerformanceDataRow,
} from "@blisspointmedia/bpm-types/dist/Performance";

interface MetricsTableViewProps {
  columnMetaDataMap: ColumnMetaDataMap;
  dates: DateRange;
  dimensionColumnMetaDataMap: ColumnMetaDataMap;
  getDimensionCell?: (
    dimensionData: DimensionMap,
    dimensionHeader: DimensionColumn,
    ...args: any[]
  ) => CellData;
  kpi: string;
  kpiMetaData: GetKpiMetaDataResponse;
  metricsTableFetchFilterBar: JSX.Element | undefined;
  prefix: string;
  setMetricsTableFetchFilterBar: StateSetter<JSX.Element | undefined>;
}

const metricsPreset: Partial<MetricsTablePreset> = {
  dimensionColumns: [
    {
      dimensionTypeName: "Channel",
      dimensionVarName: "Channel",
      divider: false,
      id: uuid.v4(),
      label: "Channel",
      role: 10,
    },
    {
      dimensionTypeName: "Platform",
      dimensionVarName: "Platform",
      divider: false,
      id: uuid.v4(),
      label: "Platform",
      role: 10,
    },
  ],
  dataColumns: [
    {
      dataVarName: Metric.CLICKS,
      id: uuid.v4(),
      label: METRIC_TO_PRETTY_NAME[Metric.CLICKS],
      role: 10,
    },
    {
      dataVarName: Metric.SPEND,
      id: uuid.v4(),
      role: 10,
    },
    {
      dataVarName: Metric.IMPRESSIONS,
      id: uuid.v4(),
      role: 10,
    },
    {
      dataVarName: Metric.VOLUME,
      id: uuid.v4(),
      label: METRIC_TO_PRETTY_NAME[Metric.VOLUME],
      role: 10,
    },
    {
      dataVarName: Metric.REVENUE,
      id: uuid.v4(),
      label: METRIC_TO_PRETTY_NAME[Metric.REVENUE],
      role: 10,
    },
    {
      dataVarName: Metric.CPM,
      id: uuid.v4(),
      label: METRIC_TO_PRETTY_NAME[Metric.CPM],
      role: 10,
    },
    {
      dataVarName: Metric.ROAS,
      heatMapping: { colorScheme: "sequential" },
      id: uuid.v4(),
      label: METRIC_TO_PRETTY_NAME[Metric.ROAS],
      role: 10,
    },
    {
      dataVarName: Metric.CPX,
      heatMapping: { colorScheme: "sequential" },
      id: uuid.v4(),
      label: METRIC_TO_PRETTY_NAME[Metric.CPX],
      role: 10,
    },
  ],
};

const Metrics: React.FC<MetricsTableViewProps> = ({
  columnMetaDataMap,
  dates,
  dimensionColumnMetaDataMap,
  getDimensionCell,
  kpi,
  kpiMetaData,
  prefix,
}) => {
  const {
    company,
    fetchingTablePresets,
    filterID,
    filterSource,
    filterState,
    getTablePresets,
    metricsTableDataKey,
    metricsTableDataMap,
    pagePreset,
    setFetchingTablePresets,
    setMetricsTableDataMap,
    setURL,
    tablePresetName,
    tablePresets,
    customSegments,
    platformAsSourceOfData,
  } = useContext(CrossChannelContext);
  const [filter, setFilter] = useState<{ filter: (line: Record<string, any>) => boolean }>();
  const [filteredGlossary, setFilteredGlossary] = useState<GlossaryItem[]>([]);
  const [rerenderTable, setRerenderTable] = useState(false);
  const [showEditTablePresetModal, setShowEditTablePresetModal] = useState<boolean>(false);
  const [showNewTablePresetModal, setShowNewTablePresetModal] = useState<boolean>(false);
  const [showRenameTablePresetModal, setShowRenameTablePresetModal] = useState<boolean>(false);
  const [metricTableDataLoading, setMetricsTableDataLoading] = useState<boolean>(false);
  const [tableHeight, setTableHeight] = useState(250);
  const [tablePresetChanges, setTablePresetChanges] = useState<MetricsTablePreset>(
    {} as MetricsTablePreset
  );
  const [tablePresetMap, setTablePresetMap] = useMap<string, MetricsTablePreset | undefined>();
  const [tableWidth, setTableWidth] = useState(500);
  const companyInfo = useCompanyInfo(company);
  const containerRef = useRef(null);
  const defaultTablePresetNames = R.keys(dimensionColumnMetaDataMap);
  const isExperimental =
    process.env.REACT_APP_DOCKER_LAMBDA_HOST === "https://api-staging-2.blisspointmedia.com" ||
    window.location.hostname === "localhost";
  const isInternal = useSelector(UserRedux.isInternalSelector);
  const setError = useSetError();
  const tablePreset = tablePresetMap[`${tablePresetName}`];
  const [exportedData, setExportedData] = useState<(string | number)[][]>([]);

  const tablePresetID: SimpleMetricsTablePreset | undefined = useMemo(
    () =>
      R.find(
        preset => preset.name === tablePresetName,
        R.defaultTo([], tablePresets) as SimpleMetricsTablePreset[]
      ),
    [tablePresetName, tablePresets]
  );

  const data = useMemo(
    () =>
      metricsTableDataMap[metricsTableDataKey] as GetPageDataResponse<
        PerformanceDataRow<CC.Dimension, CC.ColumnDataRow>
      >,
    [metricsTableDataKey, metricsTableDataMap]
  );

  // Table Preset Callbacks
  const getTablePreset = useCallback(
    params => {
      return (async () => {
        try {
          setFetchingTablePresets(true);
          const res = await SingleChannelLambdaFetch<GetTablePresetParams>(
            "/metrics_table_preset",
            {
              params,
            }
          );
          return await awaitJSON<MetricsTablePreset>(res);
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to fetch table preset. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          setFetchingTablePresets(false);
        }
      })();
    },
    [setError, setFetchingTablePresets]
  );

  const saveTablePreset = useCallback(
    body => {
      return (async () => {
        let presetResponse;
        try {
          setFetchingTablePresets(true);
          const res = await SingleChannelLambdaFetch<SaveTablePresetParams>(
            "/metrics_table_preset",
            {
              method: "POST",
              body,
            }
          );
          presetResponse = await awaitJSON<SaveTablePresetResponse>(res);
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to save table preset. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          getTablePresets();
          return presetResponse;
        }
      })();
    },
    [getTablePresets, setError, setFetchingTablePresets]
  );

  const duplicateTablePreset = useCallback(
    preset => {
      (async () => {
        const fullyLoadedPreset =
          preset && !R.isNil(preset.name) && tablePresetMap[`${preset.name}`]
            ? tablePresetMap[`${preset.name}`]
            : await getTablePreset({ id: preset.id, company, mediatype: prefix });
        if (!tablePresetMap[`${preset.name}`] && fullyLoadedPreset) {
          setTablePresetMap(`${preset.name}`, fullyLoadedPreset);
        }
        saveTablePreset({
          company,
          id: "new",
          mediatype: prefix,
          preset: fullyLoadedPreset,
          name: `${preset.name} (Copy)`,
        });
      })();
    },
    [company, getTablePreset, prefix, saveTablePreset, setTablePresetMap, tablePresetMap]
  );

  const deleteTablePreset = useCallback(
    params => {
      return (async () => {
        try {
          setFetchingTablePresets(true);
          const res = await SingleChannelLambdaFetch<DeleteTablePresetParams>(
            "/metrics_table_preset",
            {
              method: "DELETE",
              params,
            }
          );
          await awaitJSON(res);
        } catch (e) {
          const error = e as Error;
          setError({
            message: `Failed to delete table preset. Error: ${error.message}`,
            reportError: error,
          });
        } finally {
          getTablePresets();
        }
      })();
    },
    [getTablePresets, setError, setFetchingTablePresets]
  );

  // Metrics Table Data Fetch
  const fetchMetricsTableData = useCallback(
    async ({ dates, byDate = false }) => {
      try {
        if (tablePresetID) {
          const dateRangeToUse = dates;
          const params: any = {
            ...(dateRangeToUse || {}),
            pageID: R.defaultTo({ id: 0 }, pagePreset).id,
            company,
            tableID: tablePresetID.id,
            id: 0, // TODO: remove this
            kpi,
            platformAsSource: JSON.stringify(platformAsSourceOfData),
          };
          if (isExperimental) {
            params.lag = companyInfo.streaming_performance_default_lag;
          }
          // We don't want to check for the filter state if we don't have a preset and
          // if we haven't loaded the filter state from the page/filter preset
          if (filterSource === "currentState" && !R.isNil(filterState)) {
            let filterStateWithCustomSegments = {
              ...filterState,
              customSegments,
            };
            params.filterState = JSON.stringify(filterStateWithCustomSegments);
          } else if (filterSource === "currentFilterID" && !R.isNil(filterID)) {
            params.filterID = filterID;
          } else if (
            pagePreset &&
            filterSource === "presetFilterID" &&
            !R.isNil(pagePreset.filterInfo.filterID)
          ) {
            params.filterID = pagePreset.filterInfo.filterID;
          } else if (
            pagePreset &&
            filterSource === "presetState" &&
            pagePreset.filterInfo.filterState
          ) {
            let filterStateWithCustomSegments = {
              ...pagePreset.filterInfo.filterState,
              customSegments,
            };
            params.filterState = JSON.stringify(filterStateWithCustomSegments);
          }

          if (byDate) {
            params.byDate = "true";
          }

          const res = await CrossChannelLambdaFetch("/getCrossChannelMetricsTableData", {
            params,
          });
          const data = await awaitJSON(res);
          return data;
        }
      } catch (e) {
        const reportError = e as Error;
        setError({
          reportError,
          message: reportError.message,
        });
      }
    },
    [
      company,
      companyInfo.streaming_performance_default_lag,
      customSegments,
      filterID,
      filterSource,
      filterState,
      isExperimental,
      kpi,
      pagePreset,
      platformAsSourceOfData,
      setError,
      tablePresetID,
    ]
  );

  // Metrics Table Data Fetch and Set
  const fetchAndSetMetricsTableData = useCallback(
    (key, dates) => {
      (async () => {
        if (tablePresetID) {
          try {
            const data = await fetchMetricsTableData({ dates });
            setMetricsTableDataMap(map => ({ ...map, [key]: data }));
          } catch (e) {
            const error = e as Error;
            setError({
              reportError: error,
              message: error.message,
            });
          }
        }
        setMetricsTableDataLoading(false);
      })();
    },
    [tablePresetID, fetchMetricsTableData, setMetricsTableDataMap, setError]
  );

  const dataFetchKey = useMemo(
    () => `${kpi || undefined}_${companyInfo.streaming_performance_default_lag}`,
    [companyInfo.streaming_performance_default_lag, kpi]
  );

  // This allows exporting table data by day. Needs to refetch data with byDate set to true,
  // and then do similar processing of exportedData that the Metrics Table handles.
  const exportTableDataByDay = useCallback(async () => {
    const data = await fetchMetricsTableData({ dates, byDate: true });
    const role = 10;
    const preset = R.defaultTo(metricsPreset, tablePreset) as MetricsTablePreset;
    const dataHeaders = getDataHeaders(preset, role);
    const dimensionDataHeaders = getDimensionDataHeaders(preset, role);
    // Need to manually add Date column to dimensionDataHeaders
    dimensionDataHeaders.push({
      id: "",
      role: 10,
      // @ts-ignore
      dimensionVarName: "Date",
      // @ts-ignore
      dimensionTypeName: "Date",
    });

    const { dimensionData, tableData } = constructTableData(
      columnMetaDataMap,
      dataHeaders,
      dimensionDataHeaders,
      getDimensionCell || DEFAULT_GET_DIMENSION_CELL,
      () => dataFetchKey,
      data.data
    );

    const sorting = preset.defaultSorting || [];

    const [sortedDimensionData, sortedTableData] = sortData(
      dataHeaders,
      dimensionData,
      dimensionDataHeaders,
      sorting,
      tableData,
      true
    );

    const numDimCols = dimensionDataHeaders.length;
    const numDataCols = dataHeaders.length;

    const dataToExport = getExportedData({
      columnMetaDataMap,
      dataHeaders,
      dimensionDataHeaders,
      numDataCols,
      numDimCols,
      sortedDimensionData,
      sortedTableData,
    });

    const csvContent = R.map(
      e =>
        e
          .map(cell =>
            typeof cell === "number"
              ? cell
              : typeof cell === "string"
              ? cell.replace(/,/g, "")
              : cell
          )
          .join(","),
      dataToExport
    ).join("\n");
    download(csvContent, `Metrics_Table_Kpi_${kpi}_byDay.csv`, "text/csv");
  }, [
    fetchMetricsTableData,
    dates,
    tablePreset,
    columnMetaDataMap,
    getDimensionCell,
    kpi,
    dataFetchKey,
  ]);

  const exportTableData = useCallback(() => {
    const csvContent = R.map(
      e =>
        e
          .map(cell =>
            typeof cell === "number"
              ? cell
              : typeof cell === "string"
              ? cell.replace(/,/g, "")
              : cell
          )
          .join(","),
      exportedData
    ).join("\n");
    download(csvContent, `Metrics_Table_Kpi_${kpi}.csv`, "text/csv");
  }, [exportedData, kpi]);

  // Metrics Table Controls
  const tableFilterBar = (
    <div className="filterBarContainer">
      <FilterBar
        hasAdvanced={false}
        onFilter={filter => {
          setFilter({ filter });
        }}
        options={
          metricsPreset && metricsPreset.dimensionColumns
            ? R.map(col => col.dimensionTypeName, metricsPreset.dimensionColumns)
            : []
        }
        size="lg"
        width={770}
      />
    </div>
  );

  const tablePresetPicker = (
    <PresetDropdown
      addPresetOnClick={() => {
        setTablePresetChanges(
          changes => ({ ...changes, id: "new", name: "My New Table Preset" } as any)
        );
        setShowNewTablePresetModal(true);
      }}
      addPresetText={"Add New Table Preset"}
      applyOnClick={tablePreset => {
        if (pagePreset) {
          setURL(pagePreset.name, tablePreset.name);
        }
      }}
      cancelOnClick={() => {}}
      className={`tablePresetPicker ${
        fetchingTablePresets || R.isNil(tablePresets) ? "loading" : ""
      }`}
      defaultPresetNames={defaultTablePresetNames}
      deleteItemOnClick={presetID => deleteTablePreset({ id: presetID, mediatype: prefix })}
      duplicateItemOnClick={preset => duplicateTablePreset(preset)}
      editItemOnClick={preset => {
        (async () => {
          const fetchedTablePreset = (tablePresetMap[`${preset.name}`]
            ? tablePresetMap[`${preset.name}`]
            : await getTablePreset({
                id: preset.id,
                company,
                mediatype: prefix,
              })) as MetricsTablePreset;
          if (!R.isNil(tablePresetMap[`${preset.name}`])) {
            setTablePresetMap(`${preset.name}`, fetchedTablePreset);
          }
          setTablePresetChanges(fetchedTablePreset);
          setShowEditTablePresetModal(true);
        })();
      }}
      hasTicks={true}
      label={
        tablePreset && !R.isNil(tablePreset.id)
          ? `Table Preset: ${tablePreset.temporary ? "Temp" : tablePreset.name}`
          : "Load Table Preset"
      }
      presets={R.filter(
        preset => !preset.temporary || (!R.isNil(tablePreset) && preset.id === tablePreset.id),
        R.defaultTo([], tablePresets)
      )}
      renameItemOnClick={presetID => {
        setTablePresetChanges({ id: presetID } as any);
        setShowRenameTablePresetModal(true);
      }}
      selectedPreset={tablePreset}
    />
  );

  const [showColorScale, setShowColorScale] = useState<boolean>(false);
  const colorScaleItems: { label: string; style }[] = [
    { label: "", style: { backgroundColor: "#CBD2E1" } },
    { label: "", style: { backgroundColor: "#E1E6EF" } },
    { label: "", style: { backgroundColor: "#F1F3F9" } },
    { label: "Avg", style: { backgroundColor: "#FFFFFF", outline: "1px solid #BEEB80" } },
    { label: "", style: { backgroundColor: "#CBEF99" } },
    { label: "", style: { backgroundColor: "#B1E666" } },
    { label: "", style: { backgroundColor: "#7ED600" } },
  ];
  const colorScale = showColorScale ? (
    <Button
      className="colorScaleButton"
      design="secondary"
      onClick={() => setShowColorScale(false)}
      size={"lg"}
      type={ButtonType.OUTLINED}
      variant={ButtonFrameworkVariant.NO_ICON}
    >
      <div className="colorScale">
        {R.map(
          item => (
            <div style={item.style}>{item.label}</div>
          ),
          colorScaleItems
        )}
      </div>
    </Button>
  ) : (
    <Button
      className="showColorScaleButton"
      design="secondary"
      icon={<ColorScale />}
      onClick={() => setShowColorScale(true)}
      size={"lg"}
      type={ButtonType.OUTLINED}
      variant={ButtonFrameworkVariant.LEADING_ICON}
    >
      See Color Scale
    </Button>
  );
  const [showGlossary, setShowGlossary] = useState<boolean>(false);
  const glossaryButton = (
    <Button
      className="glossaryButton"
      design="secondary"
      icon={<MdViewHeadline />}
      onClick={() => setShowGlossary(true)}
      size={"lg"}
      type={ButtonType.FILLED}
      variant={ButtonFrameworkVariant.LEADING_ICON}
    >
      Glossary
    </Button>
  );

  const downloadButton = (
    <DownloadDropdown
      menuOptions={["Download table data", "Download table data by date"]}
      onClickOptions={[exportTableData, exportTableDataByDay]}
    />
  );

  const metricsTableControls = (
    <div className="metricsTableControlsContainer">
      {tableFilterBar}
      <div className="metricsTableControls">
        {isInternal && tablePresetPicker}
        {isInternal && colorScale}
        {isInternal && glossaryButton}
        {downloadButton}
      </div>
    </div>
  );

  // Metrics Table
  const metricsTable = data && !fetchingTablePresets && (
    <MetricsTableWithPresets
      columnMetaDataMap={{ ...columnMetaDataMap, ...dimensionColumnMetaDataMap }}
      data={R.defaultTo([], data.data) as any}
      dataFetchKey={() => dataFetchKey}
      filter={filter ? filter.filter : undefined}
      getDimensionCell={getDimensionCell}
      height={tableHeight}
      preset={R.defaultTo(metricsPreset, tablePreset) as MetricsTablePreset}
      setExportedData={exportedData => {
        setExportedData(exportedData);
      }}
      width={tableWidth}
      exportedData={exportedData}
    />
  );

  // Table Container
  const metricsTableBody =
    data && data.data && tablePreset ? metricsTable : <PerformanceGridSkeleton />;

  // Modals
  const glossaryModal = showGlossary && (
    <Modal
      show
      centered
      size="lg"
      onHide={() => {
        setFilteredGlossary(GLOSSARY);
        setShowGlossary(false);
      }}
      className="metricsGlossaryContainer"
    >
      <Modal.Header closeButton>
        <Modal.Title>Metrics Glossary</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="glossaryFilterContainer">
          <FilterBar
            className="glossaryFilter"
            onFilter={
              ((filter: (line: GlossaryItem) => boolean) => {
                setFilteredGlossary(R.filter(filter, GLOSSARY));
              }) as any
            }
            hasAdvanced={false}
            options={["term", "definition"]}
            variant="Dropdown"
            width={776}
          />
        </div>
        <div className="glossaryContent">
          {R.sortBy(elem => elem.term, filteredGlossary).map(({ term, definition }) => (
            <div key={term} className="section">
              <div className="term">{term}</div>
              <div className="definition">{definition}</div>
            </div>
          ))}
        </div>
      </Modal.Body>
    </Modal>
  );

  const renameTablePresetModal = showRenameTablePresetModal && (
    <RenameModal
      applyOnClick={newName => {
        (async () => {
          await saveTablePreset({
            company: company,
            id: tablePresetChanges.id,
            mediatype: prefix,
            name: newName,
            temporary: R.defaultTo(false, tablePresetChanges.temporary),
          });
          const presetInMap = tablePresetMap[`${tablePresetChanges.name}`];
          if (presetInMap) {
            setTablePresetMap(`${tablePresetChanges.name}`, {
              ...presetInMap,
              name: newName,
            });
          }
          if (tablePresetID && tablePresetChanges.id === tablePresetID.id) {
            setTablePresetChanges({ ...tablePresetChanges, name: newName });
          }
          setShowRenameTablePresetModal(false);
          if (pagePreset) {
            setURL(pagePreset.name, newName);
          }
        })();
      }}
      applyText={"Save"}
      cancelText={"Discard"}
      onCancel={() => {
        setShowRenameTablePresetModal(false);
        if (tablePreset) {
          setTablePresetChanges(tablePreset);
        }
      }}
      onHide={() => {
        setShowRenameTablePresetModal(false);
        if (tablePreset) {
          setTablePresetChanges(tablePreset);
        }
      }}
      placeholder="Enter new preset name"
      subTitle={"New Table Preset Name"}
      title={`Rename ${
        tablePresetChanges && tablePresetChanges.name ? tablePresetChanges.name : ""
      }`}
    />
  );

  const editTablePresetModal = (showEditTablePresetModal || showNewTablePresetModal) &&
    tablePreset && (
      <EditTablePresetModal
        columnMetaDataMap={columnMetaDataMap}
        defaultPresetNames={defaultTablePresetNames}
        dimensionColumnMetaDataMap={dimensionColumnMetaDataMap}
        kpiMetaData={kpiMetaData}
        prefix={prefix as "cross_channel"}
        onHide={() => {
          setShowEditTablePresetModal(false);
          setShowNewTablePresetModal(false);
          if (tablePreset) {
            setTablePresetChanges(tablePreset);
          }
        }}
        presetChanges={tablePresetChanges}
        setPresetChanges={setTablePresetChanges}
        saveAndApplyOnClick={tablePreset => {
          (async () => {
            const newID = await saveTablePreset({
              company,
              companyToUseInDB: company,
              id:
                tablePreset && !R.isNil(tablePreset.id) && tablePreset.id > 0
                  ? tablePreset.id
                  : "new",
              name: tablePreset.name,
              mediatype: prefix,
              preset: tablePreset,
              temporary: tablePreset.temporary,
            });
            if (
              pagePreset &&
              ((tablePreset.id as any) === "new" ||
                tablePreset.id === tablePresetID?.id ||
                tablePreset.temporary)
            ) {
              const tablePresetName =
                tablePreset.temporary &&
                (R.isNil(tablePreset.id) ||
                  (tablePreset.id as any) === "new" ||
                  tablePreset.id <= 0)
                  ? newID.name
                  : tablePreset.name;
              setURL(pagePreset.name, tablePresetName);
              setMetricsTableDataMap(map => ({ ...map, [metricsTableDataKey]: undefined }));
            }
            setTablePresetMap(`${newID.name}`, {
              ...tablePresetChanges,
              name: (newID || {}).name,
              id: (newID || {}).id,
              temporary: tablePreset.temporary,
            });
            setShowEditTablePresetModal(false);
            setShowNewTablePresetModal(false);
          })();
        }}
      />
    );

  // useEffects
  useEffect(() => {
    if (company && !tablePresets) {
      getTablePresets();
    }
  }, [company, getTablePresets, tablePresets]);

  useEffect(() => {
    (async () => {
      if (R.isNil(metricsTableDataMap[metricsTableDataKey]) && !metricTableDataLoading) {
        setMetricsTableDataLoading(true);
        await fetchAndSetMetricsTableData(metricsTableDataKey, dates);
      }
    })();
  }, [
    dates,
    fetchAndSetMetricsTableData,
    kpi,
    metricsTableDataKey,
    metricsTableDataMap,
    metricTableDataLoading,
  ]);

  useEffect(() => {
    if (!R.isNil(tablePresetName) && !tablePreset && tablePresetMap && !fetchingTablePresets) {
      (async () => {
        if (tablePresetID) {
          try {
            const tablePreset = await getTablePreset({
              id: `${tablePresetID.id}`,
              company,
              mediatype: prefix,
            });
            if (pagePreset && tablePreset) {
              setTablePresetMap(`${tablePresetID.name}`, tablePreset);
              setTablePresetChanges(tablePreset);
              setURL(pagePreset.name, tablePreset.name);
            }
          } catch (e) {
            const error = e as Error;
            setError({
              reportError: error,
              message: error.message,
            });
          }
        }
      })();
    }
  }, [
    tablePreset,
    tablePresetID,
    setTablePresetMap,
    tablePresetMap,
    company,
    prefix,
    setError,
    getTablePreset,
    tablePresetName,
    tablePresets,
    setURL,
    pagePreset,
    fetchingTablePresets,
  ]);

  // Component Sizing
  const windowResizeFunction = () => setRerenderTable(true);
  if (window && window.onresize !== windowResizeFunction) {
    window.onresize = windowResizeFunction;
  }

  useLayoutEffect(() => {
    const newTableHeight = containerRef.current
      ? (containerRef.current as any).offsetHeight
      : undefined;
    const newTableWidth = containerRef.current
      ? (containerRef.current as any).offsetWidth
      : undefined;

    if (newTableHeight) {
      setTableHeight((containerRef.current as any).offsetHeight);
    }
    if (newTableWidth) {
      setTableWidth((containerRef.current as any).offsetWidth);
    }

    if (rerenderTable) {
      setRerenderTable(false);
    }
  }, [rerenderTable]);

  useLayoutEffect(() => {
    if (rerenderTable) {
      setRerenderTable(false);
    }
  }, [rerenderTable]);

  return (
    <div
      className="metricsTableWithControlsContainer"
      style={{ display: "flex", flexDirection: "column", flex: 1 }}
    >
      {editTablePresetModal}
      {renameTablePresetModal}
      {glossaryModal}
      {metricsTableControls}
      <div style={{ display: "flex", flex: 1 }} id="metricsTableContainer" ref={containerRef}>
        {metricsTableBody}
      </div>
    </div>
  );
};

export default Metrics;
