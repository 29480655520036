import { getBeeswaxIntegrationFlipDate } from "./beeswaxIntegrationExperiment";
import {
  shouldEnableAudioGraphPerformance,
  shouldEnableDisplayGraphPerformance,
  shouldEnableGraphPerformance,
} from "./graphPerformanceExperiment";
import { shouldEnableBonuserTools } from "./bonuserTools";
import { shouldEnableCustomBudgetSegments } from "./budgetIntakeToolExperiment";
import { shouldEnableBrandHealthMetrics } from "./brandHealthMetricsExperiment";
import { shouldEnableBrandHealthGoogleTabUsers } from "./brandHealthGoogleTab";
import { shouldEnableCreativeInsights } from "./creativeInsightsExperiment";
import { shouldEnableEbayIntegration } from "./ebayIntegrationExperiment";
import {
  shouldEnableFilterPresets,
  shouldEnableFilterPresetsCompanies,
} from "./filterPresetsExperiment";
import { shouldEnableInsightsCategories } from "./insightsCategoryExperiment";
import { shouldEnableKpiSetup } from "./kpiSetupExperiment";
import {
  shouldEnableLinearCampaigns,
  shouldEnableAllLinearChangesUsers,
  shouldEnableAllLinearChangesCompanies,
  shouldEnableUpfrontBuyPacingUsers,
} from "./linearBuyingExperiment";
import { shouldEnableLinearDelivery } from "./linearDeliveryExperiment";
import { shouldEnableMarketChannelColumns } from "./marketChannelColumnsExperiment";
import { shouldEnableMasterTargeting } from "./masterTargetingExperiment";
import { shouldEnableMediaUpdateGenerator } from "./MediaUpdateGenerator";
import { shouldEnableMobiusIntegration } from "./mobiusIntegrationExperiment";
import { shouldEnableFlashtalkingAPI } from "./flashtalkingExperiment";
import { shouldEnableMobiusSlidesCompany } from "./mobiusSlidesExperiment";
import { shouldEnableNewNavBar } from "./newNavBarExperiment";
import { shouldEnableNewSlidesForUser } from "./newSlidesExperiment";
import { shouldEnableRadioBuying } from "./radioBuyingExperiment";
import { shouldEnableStreamingCreativesImporter } from "./streamingCreativesImporterExperiment";
import { shouldEnableStreamingGeoTargeting } from "./streamingGeoTargetingExperiment";
import { shouldEnableStreamingOptimizationUpdates } from "./streamingOptimizationExperiment";
import { shouldEnableStreamingPlanBuilder } from "./streamingPlanBuilderExperiment";
import { shouldEnableTimeTravel } from "./timeTravelExperiment";
import { shouldNotDefaultHomeV2 } from "./homeV2Experiment";
import React, { useContext } from "react";
import { shouldEnableStreamingNetworksUpdates } from "./streamingNetworksExperiment";
import { shouldEnableStreamingMonitoring } from "./streamingMonitoringExperiment";
import { shouldEnableNewFetchFilterBar } from "./newFetchFilterBarExperiment";
import { shouldEnableMMMOfflineInputs } from "./mmmOfflineInputs";
import { shouldEnableMMMNavbarGui } from "./mmmNavbarGuiExperiment";
import { shouldEnableMetaBuying } from "./metaBuyingExperiment";
import { shouldEnableCustomerInsights } from "./customerInsightsExperiment";
import { shouldEnableYouTubePlanning } from "./youTubePlanningExperiment";

export const engineeringUsers = [
  "alex.echeverria@tinuiti.com",
  "benjamin.contesse@tinuiti.com",
  "berto.garciacarrillo@tinuiti.com",
  "brenna.huggins@tinuiti.com",
  "carling.sugarman@tinuiti.com",
  "garrett.cheadle@tinuiti.com",
  "hinling.yeung@tinuiti.com",
  "jp.walker@tinuiti.com",
  "justin.manus@tinuiti.com",
  "katie.tensfeldt@tinuiti.com",
  "kyle.chan@tinuiti.com",
  "kyle.sanclemente@tinuiti.com",
  "miguel.garcia@tinuiti.com",
  "nicole.yu@tinuiti.com",
  "scott.silveus@tinuiti.com",
  "vinnie.jaidar@tinuiti.com",
  "skye.bishop@tinuiti.com",
  "katya.villano@tinuiti.com",
];

export const productUsers = [
  "micah.kilimann@tinuiti.com",
  "lauren.wolfen@tinuiti.com",
  "sean.odlum@tinuiti.com",
];

export const econometricsUsers = [
  "philip.rundall@tinuiti.com",
  "doug.macdonald@tinuiti.com",
  "taurey.carr@tinuiti.com",
  "will.hammond@tinuiti.com",
  "galen.murray@tinuiti.com",
  "morgan.stockham@tinuiti.com",
  "paul.sims@tinuiti.com",
  "christopher.nilsson@tinuiti.com",
];
/*
  Experiment Context + hooks
  ----------------------------------------------------------------------------------------------------
  Used to set and access experiments throughout the app.
  */

export const ExperimentContext = React.createContext<Record<string, boolean | string>>({});

export const useExperimentContext = (): Record<string, boolean | string> => {
  const experiments = useContext(ExperimentContext);
  if (experiments === null) {
    throw new Error("You must wrap your components in a FeatureProvider.");
  }
  return experiments;
};

export const useExperimentFlag = (experimentName: string): boolean | string => {
  const experiments = useExperimentContext();
  return experiments[experimentName];
};

/*
  Experiment Setup
  ----------------------------------------------------------------------------------------------------
  Add experiment flags and call out to experiment-specific configs here!
  All logic for whether an experiment should be enabled should live in a separate file per experiment.
  */

export const getExperimentsForUser = (userEmail: string): Record<string, boolean | string> => {
  if (!userEmail) {
    return {};
  }

  return {
    enableBonuserTools: shouldEnableBonuserTools(userEmail),
    enableBrandHealthGoogleTabUsers: shouldEnableBrandHealthGoogleTabUsers(userEmail),
    enableAllLinearBuyingChanges: shouldEnableAllLinearChangesUsers(userEmail),
    enableFilterPresets: shouldEnableFilterPresets(userEmail),
    enableFlashtalkingAPI: shouldEnableFlashtalkingAPI(userEmail),
    enableKpiSetup: shouldEnableKpiSetup(userEmail),
    enableLinearDelivery: shouldEnableLinearDelivery(userEmail),
    enableMarketChannelColumns: shouldEnableMarketChannelColumns(userEmail),
    enableMasterTargeting: shouldEnableMasterTargeting(userEmail),
    enableMediaUpdateGenerator: shouldEnableMediaUpdateGenerator(userEmail),
    enableMMMOfflineInputs: shouldEnableMMMOfflineInputs(userEmail),
    enableMobiusIntegration: shouldEnableMobiusIntegration(userEmail),
    enableNewFetchFilterBar: shouldEnableNewFetchFilterBar(userEmail),
    enableNewNavBar: shouldEnableNewNavBar(userEmail),
    enableNewSlides: shouldEnableNewSlidesForUser(userEmail),
    enableStreamingCreativesImporter: shouldEnableStreamingCreativesImporter(userEmail),
    enableStreamingGeoTargeting: shouldEnableStreamingGeoTargeting(userEmail),
    enableStreamingMonitoring: shouldEnableStreamingMonitoring(userEmail),
    enableStreamingNetworksUpdates: shouldEnableStreamingNetworksUpdates(userEmail),
    enableStreamingOptimizationUpdates: shouldEnableStreamingOptimizationUpdates(userEmail),
    enableStreamingPlanBuilder: shouldEnableStreamingPlanBuilder(userEmail),
    enableTimeTravel: shouldEnableTimeTravel(userEmail),
    enableCustomerInsights: shouldEnableCustomerInsights(userEmail),
    enableYouTubePlanning: shouldEnableYouTubePlanning(userEmail),
    enableUpfrontLinearPacingView: shouldEnableUpfrontBuyPacingUsers(userEmail),
    // Add new user-based experiment flags here like this:
    // myExperimentFlag: shouldEnableMyExperimentFlag(),
  };
};
export const getExperimentsForCompany = (company: string): Record<string, boolean | string> => {
  if (!company) {
    return {};
  }

  return {
    enableLinearCampaigns: shouldEnableLinearCampaigns(company),
    enableBeeswaxIntegration: !!getBeeswaxIntegrationFlipDate(company),
    beeswaxIntegrationFlipDate: getBeeswaxIntegrationFlipDate(company),
    enableGraphPerformance: shouldEnableGraphPerformance(company),
    enableAudioGraphPerformance: shouldEnableAudioGraphPerformance(company),
    enableDisplayGraphPerformance: shouldEnableDisplayGraphPerformance(company),
    enableRadioBuying: shouldEnableRadioBuying(company),
    enableFilterPresetsCompanies: shouldEnableFilterPresetsCompanies(company),
    enableEbayIntegration: shouldEnableEbayIntegration(company),
    enableInsightsCategories: shouldEnableInsightsCategories(company),
    enableMobiusSlidesCompany: shouldEnableMobiusSlidesCompany(company),
    enableCustomBudgetSegments: shouldEnableCustomBudgetSegments(company),
    notDefaultHomeV2: shouldNotDefaultHomeV2(company),
    enableMMMNavbarGui: shouldEnableMMMNavbarGui(company),
    enableAllLinearBuyingChanges: shouldEnableAllLinearChangesCompanies(company),
    enableMetaBuying: shouldEnableMetaBuying(company),
    // Add new company-based experiment flags here like this:
    // myExperimentFlag: shouldEnableMyExperimentFlag(),
  };
};

export const getExperimentsForCompanyWithUserRole = (
  company: string,
  userRole: number
): Record<string, boolean> => {
  return {
    enableCreativeInsights: shouldEnableCreativeInsights(company, userRole),
    enableBrandHealthMetrics: shouldEnableBrandHealthMetrics(company, userRole),
    // Add new company+userRole-based experiment flags here like this:
    // myExperimentFlag: shouldEnableMyExperimentFlag(),
  };
};
