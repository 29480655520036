import { useCallback, useEffect, useState } from "react";
import {
  Dropdown,
  DropdownToggleType,
  FullPageSpinner,
  Page,
  Input,
  DatePicker,
  Button,
  ButtonType,
  Spinner,
  MenuItem,
} from "../Components";
import { useCompanyInfo } from "../redux/company";
import { Form } from "react-bootstrap";
import { TODAY } from "@blisspointmedia/bpm-types/dist/RelativeDatePicker";
import Plan from "./Plan";
import "./YouTubePlanning.scss";
import "../Components/FormCheck.scss";
import { YoutubePlanningLambdaFetch, awaitJSON } from "../utils/fetch-utils";
import { useSetError } from "../redux/modals";

export interface YouTubePlanData {
  inputBudget: number;
  suggestedBudget: number;
  onTargetReach: number;
  averageFreq: number;
  cpm: number;
  youTubePopulation: number;
  spendVsReach: { spend: number; val: number }[];
  spendVsViews: { spend: number; val: number }[];
}

const GOAL_OPTIONS = [
  "Efficient Reach (Awareness)",
  "Efficient Completions (Awareness)",
  "Maximize Views (Considerations)",
  "Online Conversions (Action)",
];

interface GoogleAdsAccount {
  account_id: string;
  brand: string;
  mcc_id: string;
}

const YouTubePlanning = (): JSX.Element => {
  const { cid } = useCompanyInfo();
  const setError = useSetError();

  const [goal, setGoal] = useState<string>();
  const [googleAdsAccountOptions, setGoogleAdsAccountOptions] = useState<MenuItem<string>[]>();
  const [googleAdsAccountData, setGoogleAdsAccountData] = useState({});
  const [googleAdsAccount, setGoogleAdsAccount] = useState<string>();
  const [queryString, setQueryString] = useState<string>();
  const [budget, setBudget] = useState<number>();
  const [dates, setDates] = useState({ start: "", end: "" });
  const [generating, setGenerating] = useState<boolean>(false);
  const [recommendationData, setRecommendationData] = useState<YouTubePlanData>();
  const [showRecommendation, setShowRecommendation] = useState<boolean>(false); // TODO: change to false when done developing rec

  useEffect(() => {
    (async () => {
      if (!googleAdsAccountOptions) {
        try {
          let res = await YoutubePlanningLambdaFetch("/getGoogleAdsAccounts", {
            method: "GET",
            params: {
              company: cid,
            },
          });
          let googleAdsAccounts: Record<string, GoogleAdsAccount> = await awaitJSON(res);

          const googleAdsAccountOptions = Object.values(googleAdsAccounts).map(account => {
            const { account_id, brand } = account;
            return {
              label: `${account_id}${brand ? `(${brand})` : ""}`,
              value: account.account_id,
            };
          });

          setGoogleAdsAccountOptions(googleAdsAccountOptions);
          setGoogleAdsAccountData(googleAdsAccounts);
        } catch (e) {
          const error = e as Error;
          setError({ message: error.message, reportError: error });
        }
      }
    })();
  }, [cid, googleAdsAccountOptions, setError]);

  const generateRecommendation = useCallback(async () => {
    setGenerating(true);
    console.log(
      `Generating recommendation for goal: ${goal}, account: ${googleAdsAccount}, budget: ${budget}, dates: ${dates.start} - ${dates.end}, query: ${queryString}.`
    );

    const googleAdsAccountInfo = googleAdsAccount ? googleAdsAccountData[googleAdsAccount] : {};

    let categories = [];

    try {
      let res = await YoutubePlanningLambdaFetch("/getCategories", {
        method: "GET",
        params: {
          company: cid,
          googleAdsAccount: googleAdsAccountInfo.account_id,
          mccId: googleAdsAccountInfo.mcc_id,
          queryString,
        },
      });
      categories = await awaitJSON(res);
    } catch (e) {
      const error = e as Error;
      setError({ message: error.message, reportError: error });
      setGenerating(false);
      return;
    }

    try {
      let res = await YoutubePlanningLambdaFetch("/createPlan", {
        method: "POST",
        body: {
          googleAdsAccount: googleAdsAccountInfo.account_id,
          mccId: googleAdsAccountInfo.mcc_id,
          categoryIds: categories,
          budget,
          startDate: dates.start,
          endDate: dates.end,
        },
      });
      let plan = await awaitJSON(res);

      const spendVsReach = plan.reachCurve.reachForecasts.map(row => {
        return { spend: row.costMicros / 1000000, val: row.forecast.onTargetReach };
      });

      const spendVsViews = plan.reachCurve.reachForecasts.map(row => {
        return { spend: row.costMicros / 1000000, val: row.forecast.views };
      });

      const planData = {
        inputBudget: budget || 0,
        suggestedBudget: 0,
        onTargetReach: plan.reachCurve.reachForecasts[0].forecast.onTargetReach,
        averageFreq: 0,
        cpm: 0,
        youTubePopulation: plan.onTargetAudienceMetrics.youtubeAudienceSize,
        spendVsReach,
        spendVsViews,
      };

      setRecommendationData(planData);
      setGenerating(false);
      setShowRecommendation(true);
    } catch (e) {
      const error = e as Error;
      setError({ message: error.message, reportError: error });
      setGenerating(false);
    }
  }, [goal, googleAdsAccount, budget, dates, queryString, googleAdsAccountData, cid, setError]);

  return (
    <Page
      app2Redesign
      title="YouTube Planning"
      pageType="YouTube Planning"
      actions={<div className="youTubePlanningActions"></div>}
    >
      {cid && googleAdsAccountOptions ? (
        <div className="youTubePlanningBody">
          {showRecommendation ? (
            <Plan planData={recommendationData} onClose={() => setShowRecommendation(false)} />
          ) : (
            <div className="planningForm">
              <div className="planningFormHeader">Build Recommendation</div>
              <div className="planningFormBody">
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Goal</div>
                  <div className="goalRadioChoice">
                    {GOAL_OPTIONS.map(goalName => {
                      return (
                        <Form.Check
                          key={goalName}
                          className="goalRadioOption"
                          type="radio"
                          label={goalName}
                          checked={goal === goalName}
                          id={goalName}
                          onChange={() => setGoal(goalName)}
                        />
                      );
                    })}
                  </div>
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Google Ads Account</div>
                  <Dropdown
                    className="googleAdsAccountDropdown"
                    type={DropdownToggleType.OUTLINED}
                    design="primary"
                    value={googleAdsAccount}
                    placeholder="Select Google Ads Account"
                    options={googleAdsAccountOptions || []}
                    onChange={setGoogleAdsAccount}
                  />
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Query</div>
                  <Input
                    type="text"
                    className="queryInput"
                    placeholder="Search for a category or company name..."
                    value={queryString || ""}
                    onChange={e => setQueryString(e.target.value)}
                  />
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Budget</div>
                  <Input
                    type="number"
                    className="budgetInput"
                    placeholder="Input Budget"
                    value={budget || ""}
                    onChange={e => setBudget(e.target.value)}
                  />
                </div>
                <div className="planningFormSection">
                  <div className="planningFormSectionTitle">Date Range</div>
                  <DatePicker
                    range={dates}
                    isOutsideRange={date => date < TODAY}
                    onChange={({ start, end }) => {
                      setDates({ start, end });
                    }}
                  />
                </div>
              </div>
              <div className="planningFormFooter">
                <Button
                  className="generateButton"
                  type={ButtonType.FILLED}
                  disabled={
                    !goal ||
                    !googleAdsAccount ||
                    !budget ||
                    !dates.start ||
                    !dates.end ||
                    !queryString
                  }
                  onClick={generateRecommendation}
                >
                  {generating ? <Spinner /> : "Generate Recommendation"}
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <FullPageSpinner />
      )}
    </Page>
  );
};

export default YouTubePlanning;
