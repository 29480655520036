import React, { useMemo, useState } from "react";
import { CampaignRow, AdSetRow, AdRow } from "@blisspointmedia/bpm-types/dist/MetaBuying";
import { Modal } from "react-bootstrap";
import { Button, ButtonType, Spinner } from "../../../Components";
import "./ReviewModal.scss";
import { ButtonFrameworkVariant } from "../../../Components/ButtonFramework";
import { MdDelete, MdEdit, MdFacebook } from "react-icons/md";

export enum ReviewModalVariant {
  DRAFT = "draft",
  PUBLISHED = "published",
  EDITING = "editing",
}

export enum ReviewModalTab {
  NONE = "None",
  DETAILS = "Details",
  AD_PREVIEW = "Ad Preview",
}

export interface ReviewModalProps {
  variant: ReviewModalVariant;
  isInternal?: boolean;
  campaign?: CampaignRow;
  adset?: AdSetRow;
  ad?: AdRow;
  escapable?: boolean;
  selectedTab?: ReviewModalTab;
  setSelectedTab?: React.Dispatch<React.SetStateAction<ReviewModalTab>>;
  onHide: () => void;
  publishAsPaused?: () => void;
  edit?: () => void;
  approve?: () => void;
  remove?: () => void;
}

// TODO: Make dynamic based on present fields
const CampaignView = ({ campaign }: { campaign: CampaignRow }): JSX.Element => {
  return (
    <div className="innerBody">
      <div className="headerRow">
        <div className="level campaign">Campaign</div>
        <div className="name">{campaign.campaign_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Campaign Objective</div>
        <div className="value">{campaign.objective}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Special Ad Categories</div>
        <div className="value">{campaign.special_ad_categories?.toLocaleString() || "None"}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Bid Strategy</div>
        <div className="value">{campaign.bid_strategy || "None"}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Budget</div>
        <div className="value">{campaign.daily_budget || campaign.lifetime_budget || "None"}</div>
      </div>
    </div>
  );
};
// TODO: Make dynamic based on present fields and display names for ids
const AdSetView = ({ adset }: { adset: AdSetRow }): JSX.Element => {
  return (
    <div className="innerBody">
      <div className="headerRow">
        <div className="level adset">Ad Set</div>
        <div className="name">{adset.adset_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Campaign</div>
        <div className="value">{adset.campaign_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Pixel</div>
        <div className="value">{adset.pixel_id}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Conversion Event</div>
        <div className="value">{adset.custom_event_type || "None"}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Optimization Goal</div>
        <div className="value">{adset.optimization_goal || "None"}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Bid Strategy</div>
        <div className="value">{adset.bid_strategy || "None"}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Bid Amount</div>
        <div className="value">{adset.bid_amount || "None"}</div>
      </div>
    </div>
  );
};
// TODO: Make dynamic based on present fields and display names for ids
const AdView = ({ ad }: { ad: AdRow }): JSX.Element => {
  return (
    <div className="innerBody">
      <div className="headerRow">
        <div className="level ad">Ad</div>
        <div className="name">{ad.ad_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Ad Set</div>
        <div className="value">{ad.adset_name}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Facebook Page</div>
        <div className="value">{ad.page_id || "None"}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Instagram Page</div>
        <div className="value">{ad.instagram_actor_id || "None"}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Creative Format</div>
        <div className="value">{ad.format || "None"}</div>
      </div>
      <div className="fieldRow">
        <div className="field">Asset</div>
        <div className="value">Depends on Creative Format</div>
      </div>
      <div className="fieldRow">
        <div className="field">Link</div>
        <div className="value">Depends on Creative Format</div>
      </div>
      <div className="fieldRow">
        <div className="field">Copy</div>
        <div className="value">Depends on Creative Format</div>
      </div>
      <div className="fieldRow">
        <div className="field">Headline</div>
        <div className="value">Depends on Creative Format</div>
      </div>
      <div className="fieldRow">
        <div className="field">Description</div>
        <div className="value">Depends on Creative Format</div>
      </div>
      <div className="fieldRow">
        <div className="field">CTA</div>
        <div className="value">Depends on Creative Format</div>
      </div>
    </div>
  );
};

export const ReviewModal = ({
  variant,
  isInternal = true,
  campaign,
  adset,
  ad,
  escapable = true,
  selectedTab = ReviewModalTab.NONE,
  setSelectedTab = () => {},
  onHide = () => {},
  publishAsPaused = () => {},
  edit = () => {},
  approve = () => {},
  remove = () => {},
}: ReviewModalProps): JSX.Element => {
  const modalTitle = useMemo(() => {
    let title = "";
    if (variant === ReviewModalVariant.DRAFT || variant === ReviewModalVariant.PUBLISHED) {
      const buildTitle = [campaign && "Campaign", adset && "Ad Set", ad && "Ad"].filter(Boolean); // Remove undefined or falsy values

      title = `Review ${buildTitle.join(" and ")}${ReviewModalVariant.DRAFT ? " Draft" : ""}`;
    } else {
      title = "Review Draft";
    }

    return title;
  }, [ad, adset, campaign, variant]);

  // Keep track of loading state for ad preview
  const [isLoading, setIsLoading] = useState(true);

  return (
    <Modal
      className="reviewModal"
      show={true}
      onHide={onHide}
      centered
      backdrop={escapable ? true : "static"}
      keyboard={escapable}
    >
      <Modal.Header closeButton className="reviewModalHeader">
        <Modal.Title>{modalTitle}</Modal.Title>
        {
          // Only show tabs if there is an ad
          ad && (
            <div className="titleButtons">
              <Button
                className={`titleButton${selectedTab === "Details" ? " selected" : " unselected"}`}
                type={ButtonType.EMPTY}
                onClick={() => {
                  setSelectedTab(ReviewModalTab.DETAILS);
                }}
              >
                Details
              </Button>
              <Button
                className={`titleButton${
                  selectedTab === "Ad Preview" ? " selected" : " unselected"
                }`}
                type={ButtonType.EMPTY}
                onClick={() => {
                  setIsLoading(true);
                  setSelectedTab(ReviewModalTab.AD_PREVIEW);
                }}
              >
                Ad Preview
              </Button>
            </div>
          )
        }
      </Modal.Header>
      <Modal.Body className="reviewModalBody">
        {campaign && selectedTab !== ReviewModalTab.AD_PREVIEW && (
          <CampaignView campaign={campaign} />
        )}
        {adset && selectedTab !== ReviewModalTab.AD_PREVIEW && <AdSetView adset={adset} />}
        {ad && selectedTab !== ReviewModalTab.AD_PREVIEW && <AdView ad={ad} />}
        {ad && selectedTab === ReviewModalTab.AD_PREVIEW && (
          <div className="adPreview">
            {isLoading && <Spinner />}
            <iframe
              title="test"
              onLoad={() => setIsLoading(false)}
              src="https://www.example.com"
              width="540"
              height="690"
              style={{ border: "none", display: isLoading ? "none" : "block" }}
            ></iframe>
          </div>
        )}
      </Modal.Body>
      {!isInternal && variant === ReviewModalVariant.DRAFT && (
        <Modal.Footer className="reviewModalFooter">
          <Button type={ButtonType.FILLED} onClick={approve}>
            Approve
          </Button>
        </Modal.Footer>
      )}
      {isInternal && variant === ReviewModalVariant.DRAFT && (
        <Modal.Footer className="reviewModalFooter">
          <div className="deleteAction">
            <Button
              variant={ButtonFrameworkVariant.ICON_ONLY}
              type={ButtonType.EMPTY}
              icon={<MdDelete />}
              onClick={remove}
            />
          </div>
          <div className="mainActions">
            <Button
              className="editButton"
              variant={ButtonFrameworkVariant.LEADING_ICON}
              icon={<MdEdit />}
              type={ButtonType.OUTLINED}
              onClick={edit}
            >
              Edit
            </Button>
            <Button
              variant={ButtonFrameworkVariant.TRAILING_ICON}
              icon={<MdFacebook />}
              type={ButtonType.FILLED}
              onClick={publishAsPaused}
            >
              Publish As Paused
            </Button>
          </div>
        </Modal.Footer>
      )}
    </Modal>
  );
};

export default ReviewModal;
